import mixins from 'vue-typed-mixins';

import StudyMixin, { STUDY_RESULT } from '@/user/mixins/StudyMixin';


export default mixins(StudyMixin).extend({

  name: 'StudyResultDialog',

  computed: {
    visible(): boolean {
      return this.studyResult !== null;
    },
    resultIcon(): string | null {
      switch (this.studyResult) {
        case STUDY_RESULT.SCORE_RETRY:
          return 'times';
        case STUDY_RESULT.UNIT_SUCCESS:
        case STUDY_RESULT.COURSE_COMPLETE:
          return 'check';
        default:
          return null;
      }
    },
    textHtml(): string {
      switch (this.studyResult) {
        case STUDY_RESULT.SCORE_RETRY:     return '<ruby>不正解<rt>ふせいかい</rt></ruby>';
        case STUDY_RESULT.UNIT_SUCCESS:    return '<ruby>正解<rt>せいかい</rt></ruby>';
        case STUDY_RESULT.COURSE_COMPLETE: return '<ruby>全問正解<rt>ぜんもんせいかい</rt></ruby>';
        default:                           return '';
      }
    },
    visibleToMenu(): boolean {
      switch (this.studyResult) {
        case STUDY_RESULT.UNIT_SUCCESS:
        case STUDY_RESULT.COURSE_COMPLETE:
          return true;
        default:
          return false;
      }
    }
  },

  methods: {
    close(): void {
      this.$emit('close');
    },
    toMenu(): void {
      if (!this.visibleToMenu) return;
      this.$emit('clickImage');
    }
  },

});
